<template>
  <b-card-code title="" no-body>
    <b-card-body>
      <b-row>
        <b-col md="2" xl="2" class="mb-1">
          <!-- basic -->
          <b-form-group label="Cari" label-for="basicInput">
            <b-form-input
              id="basicInput"
              placeholder="Search"
              v-model="search"
            />
          </b-form-group>
        </b-col>
        
        <b-col md="2" xl="2" class="mb-1">
          <!-- basic -->
          <b-form-group label="UserId" label-for="UserId">
            <b-form-input
              id="UserId"
              placeholder="1950"
              type="number"
              v-model="user_id"
            />
          </b-form-group>
        </b-col>

        <b-col md="2" xl="2" class="mb-1">
          <!-- basic -->
          <b-form-group label="Ip" label-for="Ip">
            <b-form-input
              id="Ip"
              placeholder="1.1.1"
              v-model="ip"
            />
          </b-form-group>
        </b-col>
       
        <b-col md="2" xl="2" class="mb-1">
          <!-- basic -->
          <b-form-group label="DeviceInfo" label-for="DeviceInfo">
            <b-form-input
              id="DeviceInfo"
              placeholder="android"
              v-model="device_info"
            />
          </b-form-group>
        </b-col>
      

        <b-col lg="2" md="2" class="mb-50">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            class="mt-0 mt-md-2"
            @click="refreshPage()"
          >
            <feather-icon icon="SearchIcon" class="mr-25" />
            <span>Search</span>
          </b-button>
        </b-col>
      </b-row>

      
    </b-card-body>

    <!-- table -->
    <b-table
      ref="selectableTable"
      selectable
      :select-mode="selectMode"
      :items="items"
      :fields="fields"
      responsive
      class="mb-0"
      @row-selected="onRowSelected"
    >
      
     
     
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <div></div>
      <!--pagination -->
      <div class="demo-inline-spacing pt-0">
        <b-pagination
          v-model="currentPage"
          hide-goto-end-buttons
          :total-rows="rows"
          :per-page="perPage"
        />
      </div>
    </b-card-body>
    

    
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BButton, BFormGroup, BAvatar, BBadge, BCardBody, BPagination, BFormTextarea,
  BButtonToolbar, BButtonGroup, BDropdown, BDropdownItem, BFormInput, BRow, BCol, BModal
} from 'bootstrap-vue'
import API from '@/api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCardCode,
    BTable,
    BButton,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
    BPagination,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BModal,
    BFormTextarea
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currentPage: 1,
      rows: 0,
      perPage: 20,
      modes: ['multi', 'single', 'range'],
      fields: ['id',{ key: 'user_id', label: 'UserId' },'aktivitas', 'ip', 'device_info', 'created_at'],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      
      
      search: null,
      user_id: null,
      device_info: null,
      ip: null,
      

    }
  },
  methods: {
    onRowSelected(items) {
      this.selected = items
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    refreshPage() {
      if (this.currentPage == 1) {
        this.getLog()
      }
      else {
        this.currentPage = 1
      }

    },
    async getLog() {

      try {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)

        const form = {
          limit: this.perPage,
          page: this.currentPage,
          search: this.search,
          user_id: this.user_id,
          ip: this.ip,
          device_info: this.device_info

        }
        const data   = await this.encryptRequest(API.log.logs(form))
        this.data_table = data.data.data
        this.items = data.data.data
        this.rows = data.data.total

        this.handleMsgSuccess('Berhasil tampilkan data log')
      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'Terjadi kesalahan silahkan coba lagi!')
      } finally {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }
    },
    
   
   



  },
  mounted() {
    this.getLog()
    
  },
  watch: {
    currentPage() {
      this.getLog()
    },

  }
}
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
</style>
